<template>
  <div>
    <div class="titleBar001">
      <span class="txtPic bitT007">
        <h3>客户声明</h3>
      </span>
    </div>
    <p>
      客户声明，当客户于签署栏内完成签署或点击页面确认，以向(券商名称)
      (下称(券商简称))提交开户申请，即代表客户作出以下声明：
    </p>
    <br />
    <div class="aboutC01 aboutC01-2">
      <ul>
        <li>
          客户已详细阅读确认、同意、接受、明白及愿意遵守(券商简称)《客户协议书》(包括其附件及附表)的所有条款及条件及其他(券商简称)不时发出或补充之规则或指引之所有条款及条件(统称「该等条款」)，并同意及接纳受该等条款的约束。
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
@import url(../about.css);
.aboutC01-2 ul li {
  list-style: initial;
}
</style>
